import {
  Component,
  OnInit,
  forwardRef,
  EventEmitter,
  Input,
  Output,
  ElementRef,
  ViewChild,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {ITextMaskConfig} from "@core/interfaces/text-mask";

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements OnInit, ControlValueAccessor {
  private innerValue: any;
  public maskInputConfigs = {};

  @Input() withReplace: boolean = false;
  @Input() inputId: string = ''; // ид инпута
  @Input() iconClass: string; // класс иконки
  @Input() iconSize: number; // размер иконки
  @Input() inputHeight: number = 35; // высота инпута
  @Input() type: string = 'text'; // тип инпута
  @Input() step: number = 1; // шаг для ввода чисел
  @Input() maxValue: number = null; // максимальное значение для числового поля
  @Input() minValue: number = null; // минимальное значение для числового поля
  @Input() placeholder: string = ''; // плейсхолдер
  @Input() autocomplete: string = 'off'; // автокомплит
  @Input() disabled: boolean = false; // блокировка
  @Input() readonly: boolean = false; // только чтение
  @Input() maxLength: string = '255'; // максимальное кол-во символов
  @Input() minLength: string = '0'; // минимальное кол-во символов
  @Input() disablePlaceholderAnimation = false; // блокировка анимации плейсхолдера (обычный инпут)
  @Input() mask: any[] | Function | boolean; // макса ввода
  @Input() textMaskConfigs: ITextMaskConfig; // конфигурация маски ввода
  @Input() pattern: RegExp; // паттерн ввода
  @Input() patternValue: RegExp; // паттерн ввода для ограничения ввода
  @Input() maxLengthFloat: number = null; // кол-во знаков после запятой
  @Output() OnFocus = new EventEmitter<any>(); // событие фокуса
  @Output() OnBlur = new EventEmitter<any>(); // событие снятия фокуса
  @Output() OnChange = new EventEmitter<any>(); // событие ввода
  @ViewChild('input') input: ElementRef;

  onChange: any = () => {};
  onTouched: any = () => {};

  get model(): string {
    return this.innerValue;
  }

  set model(val) {
    if (this.withReplace && this.type === 'text') {
      val = this.replaceSymbol(val, this.innerValue);
    }
    if (val !== this.innerValue) {
      this.innerValue = this.checkMask(val);
      this.input.nativeElement.value =  this.innerValue;
      this.onChange(this.innerValue);
      this.OnChange.emit(this.innerValue);
    }
  }


  constructor() { }

  ngOnInit() {
    if (!this.mask) {
      this.mask = false;
    }
    if (this.textMaskConfigs) {
      this.maskInputConfigs = { ...this.textMaskConfigs };
    } else {
      this.maskInputConfigs = {
        showMask: false,
        mask: this.mask,
        keepCharPositions: true,
        guide: false
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.mask || changes.textMaskConfigs) {
      if (!this.mask) {
        this.mask = false;
      }
      if (this.textMaskConfigs) {
        this.maskInputConfigs = { ...this.textMaskConfigs };
      } else {
        this.maskInputConfigs = {
          showMask: false,
          mask: this.mask,
          keepCharPositions: true,
          guide: false
        };
      }
    }
    if (changes.model) {
      this.innerValue = this.checkMask(changes.model.currentValue);
      this.input.nativeElement.value =  this.innerValue;
    }
  }

  writeValue(value): void {
    this.innerValue = this.checkMask(value);
    this.onChange(this.innerValue);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  emitFocusEvent() {
    this.OnFocus.emit();
  }

  emitBlurEvent() {
    this.OnBlur.emit();
    this.onTouched();
  }

  private checkMask(value: string): string {
    let result: string = '';
    let val: string = '';
    if (value) {
      if (this.patternValue) {
        for (let i = 0; i < value.length; i++) {
          if (value.charAt(i).match(this.patternValue)) {
            val += value.charAt(i);
          }
        }
      } else {
        val = value;
      }
      if (this.type === 'number' && (!!this.maxLengthFloat || this.maxLengthFloat === 0) && val.length > 0) {
        let substrs = val.split('.');
        if (substrs && substrs.length > 1 && substrs[1].length > this.maxLengthFloat) {
          val = this.maxLengthFloat > 0
            ? `${substrs[0]}.${substrs[1].substr(0, this.maxLengthFloat)}`
            : `${substrs[0]}`;
        }
      }
      if (this.maxLength) {
        result = val.length > Number(this.maxLength) ? val.substr(0, Number(this.maxLength)) : val;
      } else {
        result = val;
      }
    }
    return result;
  }

  private replaceSymbol(currentValue: string, beforeValue: string): string {
    let result = currentValue;
    let index: number = null;
    for (let i = 0; i < currentValue.length; i++) {
      if (!!beforeValue.charAt(i) && currentValue.charAt(i) !== beforeValue.charAt(i)) {
        index = i;
        break;
      }
    }
    if (index !== null) {
      result = beforeValue.replace(beforeValue.charAt(index), currentValue.charAt(index));
    }
    return result;
  }

}
