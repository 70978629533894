import { Component, EventEmitter, Output, ViewEncapsulation } from "@angular/core";
import { ColDef, GridOptions } from "ag-grid-community";
import { ContextMenuService } from "@perfectmemory/ngx-contextmenu";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { HelpGridFiltersService } from "../help-grid-filters.service";
import { HelpApiService } from "@core/services/api/help-api.service";
import {
  DownloadFileCellRendererComponent,
  IDownloadFileCellRendererComponentParams
} from "@shared/components/base/ag-base-grid/cell-renderers/download-file-cell-renderer/download-file-cell-renderer.component";
import { take } from "rxjs/operators";
import { AgBaseGridComponent, UniversalTextCellRenderer } from "ngx-strong-frontend-lib/grid";
import { IGridRequestPayload } from "ngx-strong-frontend-lib/grid/interfaces";
import { LocalStorageService } from "ngx-strong-frontend-lib/services/local-storage";

@Component({
  selector: 'app-ag-help-grid',
  templateUrl: './ag-help-grid.component.html',
  styleUrls: ['./ag-help-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgHelpGridComponent extends AgBaseGridComponent {

  public readonly gridId: string = this.helpGridFiltersService.getGridId();
  private readonly gridColsNames = this.translateService.instant('HELP.GRID_COLS');

  public readonly gridOptions: GridOptions = {
    ...this.gridOptions,
    rowClass: 'row-grid-class',
    rowHeight: 45,
    pagination: true,
    paginationPageSize: 10,
    suppressPaginationPanel: true,
    suppressRowClickSelection: true,
    multiSortKey: 'ctrl',
    rowSelection: 'single',
    columnDefs: this.getColumnDefs(this.gridColsNames)
  };

  @Output() selectedRowsChanged: EventEmitter<any> = new EventEmitter(); // Событие для детекта изменения выбранных строк

  constructor(
    protected localStorageService: LocalStorageService,
    protected contextMenuService: ContextMenuService<any>,
    protected toastr: ToastrService,
    private translateService: TranslateService,
    private helpApiService: HelpApiService,
    private helpGridFiltersService: HelpGridFiltersService
  ) {
    super(
      localStorageService,
      contextMenuService,
      toastr,
      helpGridFiltersService
    );
    this.gridCompleted
      .pipe(
        take(1)
      )
      .subscribe(() => {
        this.refreshGrid();
      });
  }

  public onRefreshPage(requestPayload: IGridRequestPayload): void {
    this.refreshPage(this.helpApiService.getHelpGrid());
  }

  protected getColumnDefs(translates: any = {}): ColDef[] {
    return [
      {
        lockPosition: true,
        headerName: translates['FILE'],
        field: 'fileName',
        width: 70,
        hide: false,
        cellRenderer: UniversalTextCellRenderer,
        cellRendererParams: { rowCount: 1 }
      },
      {
        lockPosition: true,
        headerName: '',
        field: '',
        width: 60,
        hide: false,
        sortable: false,
        cellRenderer: DownloadFileCellRendererComponent,
        cellRendererParams: <IDownloadFileCellRendererComponentParams>{
          download: (row: any) => {
            if (row?.path) {
              this.helpApiService.getHelpFile(row.fileName, row.path);
            }
          },
          isVisible: data => !!data.path,
        }
      }
    ];
  }

  public onRightClick($event) {
    return false;
  }
}
