import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {SystemParamsService} from "@core/services/system-params.service";
import {SYSTEM_PARAMETER_NAME} from "@app/app.enums";
import { OpenModalService } from "ngx-strong-frontend-lib/services/open-modal";
import {UsdService} from "@core/services/usd.service";
import {TranslateService} from "@ngx-translate/core";
import {ChangePasswordModalComponent} from "@shared/components/change-password-modal/change-password-modal.component";
import { ConfirmModalComponent } from "ngx-strong-frontend-lib/components/confirm-modal";
import {Router} from "@angular/router";

@Component({
  selector: 'app-header-support',
  templateUrl: './header-support.component.html',
  styleUrls: ['./header-support.component.scss']
})
export class HeaderSupportComponent implements OnInit, OnDestroy {

  public supportPhone: string;
  public supportEmail: string;
  public showSupportBalloon: boolean = false;
  public supBalEnableClickOutside: boolean = false;
  public showUserBalloon: boolean = false;
  public userBalEnableClickOutside: boolean = false;
  private exitTitles: string[];
  public isLoading: boolean = false; // флаг загрузки
  private publicSystemParamsSubscription: Subscription = new Subscription();

  constructor(
    private modalService: OpenModalService,
    private translateService: TranslateService,
    private usdService: UsdService,
    private systemParamsService: SystemParamsService,
    private router: Router
  ) {
    this.translateService.get(['GENERAL.LOGOUT_QUESTION', 'GENERAL.EXIT'])
      .subscribe((result: string[]) => {
      this.exitTitles = result;
    });
  }

  ngOnInit() {
   this.publicSystemParamsSubscription = this.systemParamsService.getPublicSystemParams()
      .subscribe((systemParams: any) => {
        this.supportPhone = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.SUPPORT_PHONE] : '';
        this.supportEmail = systemParams ? systemParams[SYSTEM_PARAMETER_NAME.SUPPORT_EMAIL] : '';
      });
  }

  ngOnDestroy() {
    this.publicSystemParamsSubscription.unsubscribe();
  }

  public toggleSupportBalloon(event, open: boolean): void {
    this.showUserBalloon = false;
    this.userBalEnableClickOutside = false;
    this.showSupportBalloon = open;
    this.supBalEnableClickOutside = open;
  }

  public openSupportBalloon(event): void {
    event.stopPropagation();
    this.toggleSupportBalloon(event, true);
  }

  public toggleUserBalloon(event, open: boolean): void {
    this.showSupportBalloon = false;
    this.supBalEnableClickOutside = false;
    this.showUserBalloon = open;
    this.userBalEnableClickOutside = open;
  }

  public openUserBalloon(event): void {
    event.stopPropagation();
    this.toggleUserBalloon(event, true);
  }

  public changePassword() {
    this.modalService.show({
      component: ChangePasswordModalComponent,
      options: {
        centered: true,
        windowClass: 'modal-standart password-modal',
      }
    });
    this.toggleUserBalloon(event, false);
  }

  public exit() {
    this.modalService.show({
      component: ConfirmModalComponent,
      data: {
        question: this.exitTitles['GENERAL.LOGOUT_QUESTION'],
        applyTitle: this.exitTitles['GENERAL.EXIT'],
        onApply: () => {
          this.usdService.logout();
        }
      },
      options: {
        centered: true,
        windowClass: 'modal-confirm',
      }
    });
    this.toggleUserBalloon(event, false);
  }

  public showHelp() {
    this.router.navigate(['/help']);
  }
}
