<div class="AgHelpGridClass">
  <section>
    <ag-grid-angular #agGrid
                     class="ag-theme-balham"
                     [gridOptions]="gridOptions"
                     (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </section>
  <footer>
    <div class="grid-actions">
      <div class="grid-action-item">
        <span class="font-icons icon-refresh"
              (click)="refreshGrid()"></span>
      </div>
    </div>
  </footer>
</div>