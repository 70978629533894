import {Injectable} from '@angular/core';
import {AuthService} from '@core/services/auth/auth.service';
import {LocalStorageService} from '@core/services/local-storage.service';
import {Router} from '@angular/router';
import {UsdApiService} from '@core/services/api/usd-api.service';
import {IUsdInfo} from '@core/interfaces/usd';
import {BehaviorSubject} from "rxjs";
import {Observable} from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class UsdService {

  private usd$: BehaviorSubject<IUsdInfo> = new BehaviorSubject<IUsdInfo>(null);
  private usdLocalStorageKey: string = 'usdInfo';
  private interval;

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private usdApiService: UsdApiService,
    private router: Router,
  ) {
    if (this.authService.isAuthorized()) {
      this.usdInfo = this.localStorageService.getObjectByName(this.usdLocalStorageKey);
    }
    this.updateUsdInfo();
    this.setIntervalUpdateUsdInfo();
  }

  public updateUsdInfo() {
    if (this.authService.isAuthorized()) {
      this.usdApiService.getUsdInfo().subscribe(
        (usdInfo: IUsdInfo) => {
          this.usdInfo = usdInfo;
          this.localStorageService.setObjectByName(this.usdLocalStorageKey, usdInfo);
        });
    }
  }

  public getUsdInfo(): Observable<IUsdInfo> {
    return this.usd$.asObservable();
  }

  get usdInfo(): IUsdInfo {
    return this.usd$.getValue();
  }

  set usdInfo(info: IUsdInfo) {
    this.usd$.next(info);
  }

  public logout() {
    this.authService.logout().subscribe({
      next: responce => {
        this.clearIntervalUpdateUsdInfo();
        this.clearUserSession();
      },
      error: error => {
        this.clearUserSession();
      }
    });
  }

  public clearUserSession() {
    this.usdInfo = null;
    this.localStorageService.setObjectByName(this.usdLocalStorageKey, null);
    this.router.navigate(['/auth']);
  }

  public setIntervalUpdateUsdInfo() {
    this.clearIntervalUpdateUsdInfo();
    this.interval = setInterval(() => {
      this.updateUsdInfo();
    }, 10000);
  }

  public clearIntervalUpdateUsdInfo() {
    clearInterval(this.interval);
  }

}
