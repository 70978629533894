import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {share} from "rxjs/operators";
import {ISystemParameters} from "@core/interfaces/system-params";

@Injectable({
  providedIn: 'root'
})
export class SystemParamsApiService {

  constructor(
    private httpClient: HttpClient
  ) {}

  public getPublicSystemParams(): Observable<ISystemParameters> {
    return this.httpClient.get<ISystemParameters>(`${environment.API_URL}/public/parameter`).pipe(share());
  }
}
