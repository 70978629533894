import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {Location} from '@angular/common';
import {AuthService} from '@core/services/auth/auth.service';
import {LocalStorageService} from '@core/services/local-storage.service';

@Injectable()
export class AuthGuard  {

  constructor(
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private localStorage: LocalStorageService
  ) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthorized()) {
      this.navigateToLogin(state.url);
      return false;
    }

    return true;
  }

  private navigateToLogin(url: string): void {
    this.localStorage.setObjectByName('redirectTo', url);
    this.router.navigate(['/auth']);
  }
}
