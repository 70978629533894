import { LocalStorageService } from './../local-storage.service';
import { AuthService } from '@core/services/auth/auth.service';

export const AuthInitializerFactory = (authService: AuthService, storage: LocalStorageService) => {
  return () => {
    return new Promise<any>(resolve => {
      authService.setCredentials(JSON.parse(storage.getObjectByName('auth') || null));
      resolve(null);
    });
  };
};
