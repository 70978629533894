<div class="headerClass">
  <div class="header-left">
    <div class="header-menu" (click)="toogleMenu(true)">
      <span class="font-icons icon-menu"></span>
    </div>
    <div *ngIf="usd" class="header-usd-sn" (click)="toInfoPage()">
      <span class="header-usd-sn-text">{{'GENERAL.SN' | translate}}{{usd.serialNumber ? usd.serialNumber : ''}}</span>
    </div>
    <lib-breadcrums
      class="header-breadcrums"
      [breadcrumbs]="breadcrumbs$ | async"
    ></lib-breadcrums>
  </div>
  <div class="header-right">
    <app-header-usd *ngIf="usd" class="header-usd"></app-header-usd>
    <app-header-support class="header-support"></app-header-support>
  </div>
</div>
<lib-menu></lib-menu>


