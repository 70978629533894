import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { share } from "rxjs/operators";
import { IPagedResponse } from "ngx-strong-frontend-lib/grid/interfaces";

@Injectable({
  providedIn: 'root'
})
export class HelpApiService {

  constructor(
    private httpClient: HttpClient
  ) { }

  public getHelpGrid(): Observable<IPagedResponse> {
    return this.httpClient.get<IPagedResponse>('assets/json/help-grid.json').pipe(share());
  }

  public getHelpFile(name: string, path: string) {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = path;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
