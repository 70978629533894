import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {
  IUsdInfo,
  IObjectDataInfo,
  IUpdateObjectDataInfo,
  ISystemDataInfo,
  IGsmDataInfo, INetworkConnectInfo
} from '@core/interfaces/usd';

@Injectable({
  providedIn: 'root'
})
export class UsdApiService {

  constructor(
    private httpClient: HttpClient
  ) {}

  public getUsdInfo(): Observable<IUsdInfo> {
    return this.httpClient.get<IUsdInfo>(`${environment.API_URL}/info/controller`).pipe(share());
  }

  public getObjectDataInfo(): Observable<IObjectDataInfo> {
    return this.httpClient.get<IObjectDataInfo>(`${environment.API_URL}/info/object`).pipe(share());
  }

  public updateObjectDataInfo(data: IUpdateObjectDataInfo) {
    return this.httpClient.put(`${environment.API_URL}/info/object`, data).pipe(share());
  }

  public getSystemDataInfo(): Observable<ISystemDataInfo> {
    return this.httpClient.get<ISystemDataInfo>(`${environment.API_URL}/info/system`).pipe(share());
  }

  public getGsmDataInfo(): Observable<IGsmDataInfo> {
    return this.httpClient.get<IGsmDataInfo>(`${environment.API_URL}/info/gsm`).pipe(share());
  }

  public getNetworkConnectInfo(): Observable<INetworkConnectInfo> {
    return this.httpClient.get<INetworkConnectInfo>(`${environment.API_URL}/info/network/status`).pipe(share());
  }
}
