import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "@env/environment";
import {share} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PasswordApiService {

  constructor(
    private HttpClient: HttpClient
  ) {}

  public changePassword(newPassword: string, oldPassword: string): Observable<any> {
    return this.HttpClient.put<any>(
      `${environment.API_URL}/user/password/change`,
      {newPassword: newPassword, currentPassword: oldPassword}
    ).pipe(share());
  }
}
