<div class="headerUsdClass">
  <div *ngIf="usd && usd.activeConnectionType" class="header-usd-communication">
    <div class="header-usd-communication-gsm-block">
      <span class="header-usd-communication-gsm icon-1"
            [class.active]="usd && usd.gsmSignalLevel && usd.gsmSignalLevel > 0">
      </span>
      <span class="header-usd-communication-gsm icon-2"
            [class.active]="usd && usd.gsmSignalLevel && usd.gsmSignalLevel > 1">
      </span>
      <span class="header-usd-communication-gsm icon-3"
            [class.active]="usd && usd.gsmSignalLevel && usd.gsmSignalLevel > 2">
      </span>
      <span class="header-usd-communication-gsm icon-4"
            [class.active]="usd && usd.gsmSignalLevel && usd.gsmSignalLevel > 3">
      </span>
    </div>
    <span class="header-usd-communication-text">{{'GENERAL.COMMUNICATION_TYPE'| translate}}{{usd.activeConnectionType}}</span>
  </div>
  <div *ngIf="data" class="header-usd-data" (click)="refreshPage()">
    <span class="font-icons icon-refresh header-usd-data-icon"></span>
    <span class="header-usd-data-text">{{data}}</span>
  </div>
</div>
