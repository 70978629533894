import {Injectable} from "@angular/core";
import {GRID_ID} from "@app/app.enums";
import {TranslateService} from "@ngx-translate/core";
import {DateTimeServiceLocal} from "@shared/services/date-time.service";
import { AgBaseGridFiltersService } from "ngx-strong-frontend-lib/grid";
import { LocalStorageService } from "ngx-strong-frontend-lib/services/local-storage";

@Injectable({
  providedIn: 'root'
})
export class HelpGridFiltersService extends AgBaseGridFiltersService {
  protected gridId: string = GRID_ID['HELP']; // id грида
  protected defaultFilters = {};

  protected localStorageFilterKey: string = this.getGridLocalStorageFilterKey();
  protected localStorageGridSortingKey: string = this.getGridLocalStorageSortingKey();

  constructor(
    protected translateService: TranslateService,
    protected dateTimeService: DateTimeServiceLocal,
    protected localStorageService: LocalStorageService
  ) {
    super(translateService, dateTimeService, localStorageService);
    this.initCurrentFilters();
  }
}
