import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import {ClickOutsideModule} from '@solidexpert/ng-click-outside';
import {AgGridModule} from 'ag-grid-angular';
import {InputComponent} from '@shared/components/input/input.component';
import {PasswordInputComponent} from '@shared/components/password-input/password-input.component';
import {ActionMenuModule} from 'ngx-strong-frontend-lib/components/action-menu';
import {HeaderComponent} from '@shared/components/header/header.component';
import {NoSelectStubComponent} from '@shared/components/no-select-stub/no-select-stub.component';
import {ChangePasswordModalComponent} from "@shared/components/change-password-modal/change-password-modal.component";
import {ChangePasswordFormComponent} from "@shared/components/change-password-modal/change-password-form/change-password-form.component";
import {HeaderSupportComponent} from "@shared/components/header-support/header-support.component";
import {HeaderUsdComponent} from "@shared/components/header-usd/header-usd.component";
import {DownloadFileCellRendererComponent} from "@shared/components/base/ag-base-grid/cell-renderers/download-file-cell-renderer/download-file-cell-renderer.component";
import {FileSizeCellRendererComponent} from "@shared/components/base/ag-base-grid/cell-renderers/file-size-cell-renderer/file-size-cell-renderer.component";

import { CalendarModule } from 'ngx-strong-frontend-lib/components/calendar';
import { SimpleSelectModule } from 'ngx-strong-frontend-lib/components/simple-select';
import { SwitchModule } from 'ngx-strong-frontend-lib/components/switch';
import { InputModule } from 'ngx-strong-frontend-lib/components/input';
import { LoaderModule } from 'ngx-strong-frontend-lib/components/loader';
import { ButtonModule } from 'ngx-strong-frontend-lib/components/button';
import { CheckboxModule } from 'ngx-strong-frontend-lib/components/checkbox';
import { MultiSelectModule } from 'ngx-strong-frontend-lib/components/multi-select';
import { TabsModule } from 'ngx-strong-frontend-lib/components/tabs';
import { BreadcrumbsModule } from 'ngx-strong-frontend-lib/components/breadcrumbs';
import { CheckboxListModule } from 'ngx-strong-frontend-lib/components/checkbox-list';
import { ToastrComponent } from 'ngx-strong-frontend-lib/components/toastr';
import { MenuModule } from 'ngx-strong-frontend-lib/components/menu';
import { NoAccessModule } from 'ngx-strong-frontend-lib/components/no-access';
import { HelpPageComponent } from './components/help-page/help-page.component';
import { AgHelpGridComponent } from './components/help-page/ag-help-grid/ag-help-grid.component';
import { FilesListModule } from 'ngx-strong-frontend-lib/components/files-list';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NoAccessModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        NgbModule,
        ClickOutsideModule,
        AgGridModule,
        BreadcrumbsModule,
        ToastrModule.forRoot({
            toastComponent: ToastrComponent,
            positionClass: 'toast-bottom-right',
            iconClasses: {
                success: 'font-icons icon-info',
                error: 'font-icons icon-error'
            },
            enableHtml: true,
            closeButton: true,
            timeOut: 15000,
            tapToDismiss: false
        }),
        CalendarModule,
        SimpleSelectModule,
        SwitchModule,
        InputModule,
        ActionMenuModule,
        ButtonModule,
        CheckboxModule,
        MultiSelectModule,
        TabsModule,
        MenuModule,
        CheckboxListModule,
        FilesListModule,
    ],
    providers: [],
    declarations: [
        AgHelpGridComponent,
        HelpPageComponent,
        InputComponent,
        PasswordInputComponent,
        HeaderComponent,
        NoSelectStubComponent,
        ChangePasswordModalComponent,
        ChangePasswordFormComponent,
        HeaderSupportComponent,
        HeaderUsdComponent,
        DownloadFileCellRendererComponent,
        FileSizeCellRendererComponent
    ],
    exports: [
        ButtonModule,
        InputModule,
        SwitchModule,
        LoaderModule,
        SimpleSelectModule,
        CalendarModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        NgbModule,
        ClickOutsideModule,
        InputComponent,
        PasswordInputComponent,
        HeaderComponent,
        NoSelectStubComponent,
        ChangePasswordModalComponent,
        ChangePasswordFormComponent,
        HeaderSupportComponent,
        HeaderUsdComponent,
        DownloadFileCellRendererComponent,
        FileSizeCellRendererComponent,
        CheckboxModule,
        MultiSelectModule,
        TabsModule,
        CheckboxListModule,
        NoAccessModule,
        HelpPageComponent,
        AgHelpGridComponent,
        FilesListModule,
    ]
})
export class SharedModule { }
