import { ContentTypeInterceptor } from '@core/services/interceptors/content-type.interceptor';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from '@core/services/auth/auth.service';
import { AuthGuard } from '@core/guards/auth-guard.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateInitializerFactory } from '@core/services/translate-initializer.service';
import { TokenInterceptorService } from "@core/services/interceptors/token-interceptor.service";
import { AuthInitializerFactory } from "@core/services/auth/auth-initializer.service";
import { LocalStorageService } from './services/local-storage.service';
import { GridPayloadInterceptor } from './services/interceptors/grid-payload.interceptor';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GridPayloadInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: TranslateInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AuthInitializerFactory,
      deps: [AuthService, LocalStorageService],
      multi: true
    }
  ]
})
export class CoreModule { }
