import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LocalStorageService} from "@core/services/local-storage.service";
import {environment} from "@env/environment";
import {catchError, tap} from "rxjs/operators";

export interface IsAuth {
  isAuth: boolean;
  authCode: string;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isAuth: IsAuth;

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    this.isAuth = this.getAuthFromCookie();
  }

  public login(username, password): Observable<any> {
    const params = {
      login: username,
      password: password
    };
    return this.httpClient.post<any>(`${environment.API_URL}/user/login`, params)
      .pipe(tap(res => {
        this.setCredentials({isAuth: true, authCode: this.setAuthCode(username, password)});
      }));
  }

  public logout() {
    return this.httpClient.post(`${environment.API_URL}/user/logout`, {})
      .pipe(tap(() => {
        this.clearCredentials();
        console.log(this.isAuth);
      }));
  }

  public setCredentials(isAuth: IsAuth): void {
    this.isAuth = isAuth;
    this.setAuthToCookie(isAuth);
  }

  public clearCredentials(): void {
    this.isAuth = null;
    this.removeAuthFromCookie();
  }

  public isAuthorized(): boolean {
    return !!this.isAuth && !!this.isAuth.isAuth;
  }

  private getAuthFromCookie(): IsAuth {
    return JSON.parse(this.localStorageService.getObjectByName('auth'));
  }

  private setAuthToCookie(isAuth: IsAuth): void {
    this.localStorageService.setObjectByName('auth', JSON.stringify(isAuth));
  }

  private removeAuthFromCookie(): void {
    this.localStorageService.removeObjectByName('auth');
  }

  private setAuthCode(login: string, password: string): string {
    return btoa(`${login}:${password}`);
  }

  public getAuthCode() {
    return this.isAuth && this.isAuth.authCode ? this.isAuth.authCode : null;
  }
}
