import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {MenuService} from "ngx-strong-frontend-lib/services/menu";
import {UsdService} from "@core/services/usd.service";
import {IUsdInfo} from "@core/interfaces/usd";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import { BreadcrumbsService } from 'ngx-strong-frontend-lib/services/breadcrumbs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public usd: IUsdInfo;
  public mainUrl: string = '/info';
  private destroy$: Subject<void> = new Subject<void>();
  
  readonly breadcrumbs$ = this.breadcrumbsService.observeBreadcrumbs({
    renderNeighbours: 'last',
    accessObjectsKey: null
  });

  constructor(
    private router: Router,
    private menuService: MenuService,
    private usdService: UsdService,
    private breadcrumbsService: BreadcrumbsService,
  ) { }

  ngOnInit() {
    this.usdService.getUsdInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe((info: IUsdInfo) => {
        this.usd = info;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public toogleMenu(open: boolean) {
    this.menuService.setOpenedMenu(open);
  }

  public toInfoPage() {
    this.router.navigate(['/info']);
  }

}
