import { Injectable } from '@angular/core';
import { IDayMonthYear } from '@core/interfaces/select-item';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { DateTimeService } from "ngx-strong-frontend-lib/services/date-time";
import { DateFormat, date_ISO_pattern } from 'ngx-strong-frontend-lib/enums';
import { IDateTimeService } from 'ngx-strong-frontend-lib/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DateTimeServiceLocal extends DateTimeService implements IDateTimeService {
  private _dateFormat = {
    date: 'DD.MM.YYYY',
    time: 'HH:mm',
    timeWithSecond: 'HH:mm:ss',
    dateTime: 'DD.MM.YYYY HH:mm',
    dateTimeWithSecond: 'DD.MM.YYYY HH:mm:ss',
    dateTimeForDate: 'MM.DD.YYYY HH:mm',
    dateTimeWithMilliSecond: 'DD.MM.YYYY HH:mm:ss.SSSSSS',
    backendDate: 'YYYY-MM-DD'
  };

  constructor() {
    super();
    dayjs.extend(timezone);
    dayjs.extend(customParseFormat);
  }

  // возвращает строку с датой для бэка из строки в заданном формате
  public getBackendDateFromString(
    date: string,
    format: string = this._dateFormat.dateTime
  ): any {
    if (date) {
      return dayjs(date, format).unix();
    } else {
      return null;
    }
  }

  // возвращает строку с датой в заданном формате из даты с бэка
  override getDateFromBackend(
    date: unknown,
    format: string = this._dateFormat.dateTime,
    keepLocalTime: boolean = true
  ): string {
    if (this.isBackendDate(date as number)) {
      return dayjs.unix(date as number).format(format);
    }
    return super.getDateFromBackend(date as string, format, keepLocalTime);
  }

  isBackendDate(timestamp: unknown): boolean {
    if (typeof timestamp === 'number') { return true; }
    return super.isBackendDate(timestamp);
  }

  public getDateFormat(type: string): DateFormat {
    return this._dateFormat[type];
  }

  public getDateStringFromString(
    value: string,
    inputFormat: string = this._dateFormat.dateTimeWithSecond,
    outputFormat: string = this._dateFormat.date
  ): string {
    if (!value) {
      return null;
    }
    const djs = dayjs(value, inputFormat);
    if (djs.year() === 0) {
      djs.year(dayjs().year());
    }
    const output = djs.format(outputFormat);
    return output !== 'Invalid Date' ? output : null;
  }
}
