import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError} from 'rxjs/operators';
import { Router } from '@angular/router';
import {AuthService} from "@core/services/auth/auth.service";
import {LocalStorageService} from "@core/services/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService,
    private localStorage: LocalStorageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isAuthorized() && !req.headers.get('Authorization') && !req.url.includes('/login')) {
      req = this.addHeaders(req, this.authService.getAuthCode());
    }
    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && (error.status === 400
        || error.status === 401 || error.status === 403)) {
        this.saveRedirectUrl(document.location.href);
        this.router.navigate(['/auth']);
      } else {
        return throwError(error);
      }
    }));
  }

  private addHeaders(req: HttpRequest<any>, authCode: string) {
    return req.clone({
      setHeaders: {
        'Authorization': `Basic ${authCode}`,
        'Content-type': req.headers.get('Content-Type')
          ? req.headers.get('Content-Type')
          : 'application/json; charset=utf-8'
      }
    });
  }

  private saveRedirectUrl(url: string) {
    const redirectTo = url ? url.replace(document.location.origin, '') : null;
    this.localStorage.setObjectByName('redirectTo',
      redirectTo && redirectTo !== '' && redirectTo !== '/' ? redirectTo : null);
  }
}
