import {AbstractControl, ValidatorFn} from '@angular/forms';
import {password_pattern} from "@app/app.enums";
import {IValidatorArray} from "@core/interfaces/validator";


export function PasswordValidator(): ValidatorFn {
  const validationPatternAZ = new RegExp(/[A-Z]+/);
  const validationPatternMinAZ = new RegExp(/[a-z]+/);
  const validationPatternNum = new RegExp(/[0-9]+/);
  const validationPatternSpec = new RegExp(/[\@\#\$\-\_\=\;\:\<\>\`\~\!\”\№\%\^\?\&\*\(\)\+\\\|\/\.\,\’\[\]\{\}\.]+/);
  const validationPatternRus = new RegExp(password_pattern);
  return (control: AbstractControl): IValidatorArray => {
    let result: IValidatorArray = null;
    if (!validationPatternAZ.test(control.value)) {
      if (!result) {result = {password: []};}
      result['password'].push({'password-AZ': true});
    }
    if (!validationPatternMinAZ.test(control.value)) {
      if (!result) {result = {password: []};}
      result['password'].push({'password-az': true});
    }
    if (!validationPatternNum.test(control.value)) {
      if (!result) {result = {password: []};}
      result['password'].push({'password-num': true});
    }
    if (!validationPatternSpec.test(control.value)) {
      if (!result) {result = {password: []};}
      result['password'].push({'password-spec': true});
    }
    if (!validationPatternRus.test(control.value)) {
      if (!result) {result = {password: []};}
      result['password'].push({'password-rus': true});
    }
    return result;
  }
}
