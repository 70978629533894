import { ACCESS_TREE_OBJECT_TYPE } from "ngx-strong-frontend-lib/enums";
import { IAccessTreeItem } from "ngx-strong-frontend-lib/interfaces";

export const password_pattern = /[^А-Яа-яЁё\s]/;
export const login_pattern = /[a-z0-9]/;
export const name_pattern = /[А-Яа-яЁё]/;
export const number_pattern = /[0-9]/;
export const float_pattern = /[0-9.]/;
export const ipv4_pattern = /^(?!\.)((^|\.)([1-9]?\d|1\d\d|2(5[0-5]|[0-4]\d))){4}$/;
export const time_pattern = /^[0-9]{2}[:][0-9]{2}[:][0-9]{2}$/;


export enum SYSTEM_PARAMETER_NAME {
  SUPPORT_PHONE = 'SUPPORT_PHONE',
  SUPPORT_EMAIL = 'SUPPORT_EMAIL',
  PRODUCT_FULL_NAME = 'PRODUCT_FULL_NAME',
  PRODUCT_SHORT_NAME = 'PRODUCT_SHORT_NAME'
}

export enum STORAGE_KEYS {
  USER_MENU = 'menu',
  MENU_OPENED = 'menuOpened',
}

export const MENU_OPENED_URLS = ['/sensors'];

export const MENU_TREE: IAccessTreeItem[] = [
  {
    code: "m_Sensor",
    name: "Dashboard",
    sortOrder: 1,
    parentId: null,
    id: 1,
    objectType: ACCESS_TREE_OBJECT_TYPE.CARD,
  },
  {
    code: "m_Device",
    name: "Приборы учета",
    sortOrder: 2,
    parentId: null,
    id: 2,
    objectType: ACCESS_TREE_OBJECT_TYPE.MENU,
  },
  {
    code: "m_Device_Value",
    name: "Значения",
    sortOrder: 1,
    parentId: 2,
    id: 3,
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
  },
  {
    code: "m_Device_Archive",
    name: "Архивы",
    sortOrder: 2,
    parentId: 2,
    id: 4,
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
  },
  {
    code: "m_Device_Add_Device",
    name: "Добавить ПУ",
    sortOrder: 3,
    parentId: 2,
    id: 5,
    objectType: ACCESS_TREE_OBJECT_TYPE.CARD,
  },
  {
    code: "m_Device_Add_Flow_Meter",
    name: "Добавить расходомер",
    sortOrder: 4,
    parentId: 2,
    id: 6,
    objectType: ACCESS_TREE_OBJECT_TYPE.CARD,
  },
  {
    code: "m_Device_Add_Energy_Meter",
    name: "Добавить ПУ (Электричество)",
    sortOrder: 5,
    parentId: 2,
    id: 7,
    objectType: ACCESS_TREE_OBJECT_TYPE.CARD,
  },
  {
    code: "m_Device_Setting_PSD",
    name: "Настройка ПСД",
    sortOrder: 6,
    parentId: 2,
    id: 8,
    objectType: ACCESS_TREE_OBJECT_TYPE.CARD,
  },
  {
    code: "m_Info",
    name: "Информация",
    sortOrder: 3,
    id: 9,
    parentId: null,
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
  },
  {
    code: "m_History",
    name: "События",
    sortOrder: 4,
    id: 9,
    parentId: null,
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
  },
  {
    code: "m_Setting",
    name: "Конфигурации",
    sortOrder: 5,
    id: 10,
    parentId: null,
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
  }, {
    code: 'm_Diagnostic',
    name: 'Диагностика',
    sortOrder: 6,
    id: 11,
    parentId: null,
    objectType: ACCESS_TREE_OBJECT_TYPE.GRID,
  }
];
export const MENU = [
  {
    code: "m_Sensor",
    name: "Dashboard",
    sortOrder: 1
  },
  {
    code: "m_Device",
    name: "Приборы учета",
    sortOrder: 2,
    children: [
      {
        code: "m_Device_Value",
        name: "Значения",
        sortOrder: 1
      },
      {
        code: "m_Device_Archive",
        name: "Архивы",
        sortOrder: 2
      },
      {
        code: "m_Device_Add_Device",
        name: "Добавить ПУ",
        sortOrder: 3
      },
      {
        code: "m_Device_Add_Flow_Meter",
        name: "Добавить расходомер",
        sortOrder: 4
      },
      {
        code: "m_Device_Add_Energy_Meter",
        name: "Добавить ПУ (Электричество)",
        sortOrder: 5
      },
      {
        code: "m_Device_Setting_PSD",
        name: "Настройка ПСД",
        sortOrder: 6
      }
    ]
  },
  {
    code: "m_Info",
    name: "Информация",
    sortOrder: 3
  },
  {
    code: "m_History",
    name: "События",
    sortOrder: 4
  },
  {
    code: "m_Setting",
    name: "Конфигурации",
    sortOrder: 5
  }, {
    code: 'm_Diagnostic',
    name: 'Диагностика',
    sortOrder: 6,
  }
];

export const MENU_URLS = {
  m_Sensor: '/sensors',
  // m_Device: '/devices',
  m_Info: '/info',
  m_History: '/history',
  m_Setting: '/settings',
  m_Device_Value: '/devices/values',
  m_Device_Archive: '/devices/archives',
  m_Device_Add_Device: '/devices/add-device',
  m_Device_Add_Flow_Meter: '/devices/add-flow-meter',
  m_Device_Add_Energy_Meter: '/devices/add-energy-meter',
  m_Device_Setting_PSD: '/devices/setting-psd',
  m_Setting_Network: '/settings/network',
  m_Setting_Configuration: '/settings/configuration',
  m_Setting_Time: '/settings/time',
  m_Setting_Journal: '/settings/journal',
  m_Setting_NTP: '/settings/ntp',
  m_Setting_TFTP: '/settings/tftp',
  m_Setting_Refresh_PO: '/settings/refresh-software',
  m_Setting_Connect_Channels: '/settings/connect-channels',
  m_Setting_Server: '/settings/server',
  m_Diagnostic: '/diagnostic',
}

export const MENU_ICONS = {
  m_Sensor: 'icon-menu_analitic',
  m_Device: 'icon-menu_immac',
  m_Info: 'icon-info',
  m_History: 'icon-calendar_time',
  m_Setting: 'icon-menu_toir',
  m_Diagnostic: 'icon-on_check',
}

export enum GRID_ID {
  "DEVICES.ARCHIVES" = "archives",
  "HISTORY" = "history",
  "HELP" = "help",
  'PSD' = 'psd'
}

export enum Criteria {
  equals = "equals",
  EQUALS_IGNORE_CASE = "EQUALS_IGNORE_CASE",
  EQUALS_OR_NULL = "EQUALS_OR_NULL",
  notEquals = "notEquals",
  greaterThan = "greaterThan",
  lessThan = "lessThan",
  greaterOrEquals = "greaterOrEquals",
  lessOrEquals = "lessOrEquals",
  between = "between",
  in = "in",
  notIn = "notIn",
  like = "like",
  notLike = "notLike",
  startFrom = "startFrom",
  notStartFrom = "notStartFrom",
  endTo = "endTo",
  notEndTo = "notEndTo",
  isNull = "isNull",
  isNotNull = "isNotNull",
  inOrNull = "inOrNull"
}

// типы фильтров
export enum FilterType {
  text = "text",
  number = 'number',
  date = "date",
  dateRange = "dateRange",
  select = "select",
  multiSelect = "multiSelect",
  simpleDictionary = 'simpleDictionary',
  multiDictionary = 'multiDictionary'
}

export enum ResourceType {
  HOT_WATER = 'ГВС',
  HEATING = 'ЦО',
  VENTILATION = 'ВЕНТ',
  HEAT_ENERGY = 'ТЭ',
  COLD_WATER = 'ХВС',
  ELECTRIC_ENERGY = 'ЭЭ',
  GAS = 'ГАЗ'
}

export enum ResourceTypeCode {
  HOT_WATER = 'HOT_WATER',
  HEATING = 'HEATING',
  VENTILATION = 'VENTILATION',
  HEAT_ENERGY = 'HEAT_ENERGY',
  COLD_WATER = 'COLD_WATER',
  ELECTRIC_ENERGY = 'ELECTRIC_ENERGY',
  GAS = 'GAS'
}

export enum ResourceTypeIcon {
  HOT_WATER = 'icon-gvs',
  HEATING = 'icon-co',
  VENTILATION = 'icon-vent',
  HEAT_ENERGY = 'icon-heat_energy',
  COLD_WATER = 'icon-hvs',
  ELECTRIC_ENERGY = 'icon-electro',
  GAS = 'icon-gaz'
}

export enum ResourceTypeColor {
  HOT_WATER = '#f18d4a',
  HEATING = '#facd61',
  VENTILATION = '#8ce5fb',
  HEAT_ENERGY = '#e0633d',
  COLD_WATER = '#78c7e4',
  ELECTRIC_ENERGY = '#ffa734',
  GAS = '#8c94f2'
}

export enum ColType {
  VARCHAR = 'VARCHAR',
  BIGINT = 'BIGINT',
  INTEGER = 'INTEGER',
  TIMESTAMP = 'TIMESTAMP',
  BOOL = 'BOOL',
  NUMBER = 'NUMBER'
}

export enum DocumentType {
  TXT = 'TXT',
  PDF = 'PDF',
  DOC = 'DOC',
  DOCX = 'DOCX',
  RTF = 'RTF',
  XLSX = 'XLSX',
  XLS = 'XLS',
  XML = 'XML',
  JSON = 'JSON',
  ZIP = 'ZIP',
  GIF = 'GIF',
  JPEG = 'JPEG',
  PNG = 'PNG'
}

export const JOURNAL_TYPE = [
  {key: 'AUTO', value: 'Авто'},
  {key: 'SD', value: 'SD'},
  {key: 'INNER_MEMORY', value: 'Внутренняя память'}
];

export const JOURNAL_SAVE_CHANGES = [
  {key: 'ALL', value: 'Все'},
  {key: 'ONLY_SELECT', value: 'Только выбранные'},
  {key: 'NO_SAVE', value: 'Не сохранять'}
];

export const SENSOR_CLASS_LIST = [
  {key: '24hours', value: '24 часа'},
  {key: 'security', value: 'Охрана'},
  {key: 'enter', value: 'Вход'},
  {key: 'service', value: 'Сервис'},
  {key: 'state', value: 'Статус'}
];

export const SENSOR_STATE_LIST = [
  {key: 'offline', value: 'Offline'},
  {key: 'norm', value: 'Norm'},
  {key: 'almin', value: 'Almin'},
  {key: 'almaj', value: 'Almaj'},
];

export const COMMUTATION_TYPE_LIST = [
  {key: 'hardware', value: 'Аппаратная'},
  {key: 'software', value: 'Программная'},
];

export enum DeviceType {
  DEVICE = 'DM',
  FLOW_METER = 'FM',
  ENERGY_METER = 'EM'
}

