import { Component } from '@angular/core';

@Component({
  selector: 'app-file-size-cell-renderer',
  templateUrl: './file-size-cell-renderer.component.html',
  styleUrls: ['./file-size-cell-renderer.component.scss']
})
export class FileSizeCellRendererComponent {

  private params: any;
  public shown: boolean = false;
  public count: number = null;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.shown = this.params.value ? this.params.value : false;
    this.count = this.params.value ? Number(this.params.value) / 1000000 : null;
  }

}
