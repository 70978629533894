import {Injectable} from "@angular/core";
import {LocalStorageService} from "@core/services/local-storage.service";
import {AuthService} from "@core/services/auth/auth.service";
import {SystemParamsApiService} from "@core/services/api/system-params-api.service";
import {ISystemParam, ISystemParameters} from "@core/interfaces/system-params";
import {BehaviorSubject, Observable} from "rxjs";
import {SYSTEM_PARAMETER_NAME} from "@app/app.enums";

@Injectable({
  providedIn: 'root'
})
export class SystemParamsService {
  private publicSystemParamsKey: string = 'publicSystemParams';
  private publicSystemParams$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private systemParamsApiService: SystemParamsApiService
  ) {
    this.publicSystemParams$.next(this.localStorageService.getObjectByName(this.publicSystemParamsKey));
    if (!this.publicSystemParams$.getValue()) {
      this.getPublicSystemParameters();
    }
  }

  public getPublicSystemParams(): Observable<any> {
    return this.publicSystemParams$.asObservable();
  }

  public getPublicSystemParameters() {
    this.systemParamsApiService.getPublicSystemParams()
      .subscribe((systemParams: ISystemParameters) => {
        let result = {};
        if (systemParams && systemParams.systemParameters) {
          systemParams.systemParameters.forEach((param: ISystemParam) => {
            result[param.name] = param.value;
          });
        }
        this.localStorageService.setObjectByName(this.publicSystemParamsKey, result);
        this.publicSystemParams$.next(result);
      });
  }

  private preparePhone(phone: string): string {
    const code_phone = phone.substr(0, 3);
    const first_part_phone = phone.substr(3, 3);
    const second_part_phone = phone.substr(6, 2);
    const third_part_phone = phone.substr(8, 2);
    return `+7 (${code_phone}) ${first_part_phone}-${second_part_phone}-${third_part_phone}`;
  }

}
