import { Component } from '@angular/core';

type IsVisible = ((data: any) => boolean) | boolean;
export interface IDownloadFileCellRendererComponentParams {
  isVisible?: IsVisible;
  download: (data: any) => void;
}

@Component({
  selector: 'app-download-file-cell-renderer',
  templateUrl: './download-file-cell-renderer.component.html',
  styleUrls: ['./download-file-cell-renderer.component.scss']
})
export class DownloadFileCellRendererComponent {

  private params: any;
  public isVisible: boolean = false;

  constructor() { }

  agInit(params: any): void {
    this.params = params;
    const isVisible: IsVisible = this.params.isVisible;
    this.isVisible = isVisible !== undefined
      ? typeof isVisible === 'function'
        ? isVisible(this.params.data)
        : isVisible
      : true;
  }

  public download() {
    if (this.params && this.params.download) {
      this.params.download(this.params.data);
    }
  }

}
