import { AppRoutingModule } from './app.routing';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { UsdService } from "@core/services/usd.service";
import { DynamicImportTranslationLoader } from '@core/services/dynamic-import-translation-loader.service';
import { ComponentLibraryModule } from 'ngx-strong-frontend-lib/module';
import { MENU_ICONS, MENU_OPENED_URLS, MENU_URLS, STORAGE_KEYS } from './app.enums';
import { NoAccessConfig, NoAccessModule } from 'ngx-strong-frontend-lib/components/no-access';
import { SYSTEM_PARAMETER_NAME } from 'ngx-strong-frontend-lib/enums';
import { SystemParamsService } from './core/services/system-params.service';
import { map } from 'rxjs';
import { PageNotFoundConfig, PageNotFoundModule } from 'ngx-strong-frontend-lib/components/page-not-found';

function noAccessFactory(systemParamsService: SystemParamsService): NoAccessConfig {
  return {
    supportPhone$: systemParamsService.getPublicSystemParams()
      .pipe(map(res => res?.[SYSTEM_PARAMETER_NAME.SUPPORT_PHONE] ?? '')),
    supportEmail$: systemParamsService.getPublicSystemParams()
      .pipe(map(res => res?.[SYSTEM_PARAMETER_NAME.SUPPORT_EMAIL] ?? ''))
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    AgGridModule,
    ContextMenuModule,
    ComponentLibraryModule.forRoot({
      menuConfig: {
        menuOpenedUrls: MENU_OPENED_URLS,
        menuOpenedStorageKey: STORAGE_KEYS.MENU_OPENED,
        userMenuStorageKey: STORAGE_KEYS.USER_MENU,
        menuUrls: MENU_URLS,
        menuIcons: MENU_ICONS,
      },
      accessObjectsStorageKey: null,
      accessRightsStorageKey: null,
      userAccess: {}
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: DynamicImportTranslationLoader
      }
    }),
    NoAccessModule.forRoot({
      provide: NoAccessConfig,
      useFactory: noAccessFactory,
      deps: [SystemParamsService]
    }),
    PageNotFoundModule.forRoot({
      provide: PageNotFoundConfig,
      useValue: <PageNotFoundConfig>{
        mainUrl: '/sensors'
      }
    }),
    CoreModule,
    SharedModule,
    AppRoutingModule
  ],
  providers: [Title, UsdService],
  bootstrap: [AppComponent]
})
export class AppModule { }
