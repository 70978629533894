import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { cloneDeep } from "lodash";
import { IGridRequestPayload } from "@app/shared/components/base/ag-base-grid/ag-base-grid.interface";

@Injectable()
export class GridPayloadInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const body = cloneDeep(req.body);
    let newBody = body;
    if (
      body?.compositeFilters
      && Array.isArray(body.compositeFilters)
    ) {
      newBody = {
        compositeFilters: body.compositeFilters.length
          ? {
              ...body.compositeFilters[0],
              filters: body.compositeFilters[0].filters.map(p => ({ ...p, value: String(p.value) }))
            }
          : { filters: [], operation: 'and' },
        sortings: body.sortings[0],
        page: {
          pageNumber: body.page.pageNumber,
          pageSize: body.page.pageSize,
        }
      } as IGridRequestPayload;
    }
    
    req = req.clone({ body: newBody });
    return next.handle(req);
  }
}